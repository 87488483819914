import React, { useEffect } from 'react';
import TitrePage from '../components/TitrePage'
import { Grid } from '@material-ui/core'
import TextAnimation from '../components/TextAnimation'
import Qualite from '../components/Qualite'
import { useBreakpoint } from '../components/Breakpoint'

import './home.css'

export default function Home() {
    const phrases = [
        "SITE VITRINE",
        "SITE PORTFOLIO",
        "SITE CATALOGUE",
        "SITE BOUTIQUE",
    ]
    const breakpoints = useBreakpoint()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='contenu'>
            <TitrePage titre="Il était une fois, au pays de la chocolatine..." couleur="violet" />
            <div className='fondDiamant'>
                <Grid container justify="center" alignItems="center">
                    <Grid item>
                        <img alt="Ingrid Delteil" src="./ingrid-delteil.jpg" className="photoRonde" />
                    </Grid>
                    <Grid item>
                        <div className="cardDiscours">
                            <p className="guillemetOuvre">
                                “
                            </p>
                            <p>
                                Softin'Medi@ c'est une aventure qui a commencé en 2009 à Bergerac, au cœur du sud-ouest.<br />
                                Aujourd'hui installée dans les Landes, près de Dax, je me suis spécialisée dans la création de sites Internet pour les artisans, commerçants et TPE.
                            </p>
                            <p>
                                Comme une styliste du Web, je confectionne des sites sur-mesure, sans solution toute prête.<br />
                                Chaque projet est différent et respecte l'image de la personne ou de l'entreprise pour laquelle il a été conçu.
                            </p>
                            <p>
                                A très vite pour booster votre visibilité sur Internet.
                            </p>
                            <p className="guillemetFerme texteADroite">
                                ”
                            </p>
                            <p className="texteADroite">
                                Ingrid Delteil
                            </p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container justify="center" alignItems="center">
                    <Grid item>
                        <img src="offre.png" alt="Développement de sites Internet" className="imgOffre" />
                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item>
                        <TextAnimation phrases={phrases} vitesse={1000}/>
                    </Grid>
                </Grid>
                {(breakpoints['lg'] || breakpoints['xl']) ?
                    <Grid container justify="center">
                        <Grid item>
                            <div className="h2Style">NOUS AVONS L'OFFRE QUI CORRESPOND À VOS BESOINS</div>
                        </Grid>
                    </Grid> : ''
                }
                <Grid container justify="center" alignItems="flex-start" className="espaceMarge">
                    <Grid item>
                        <Qualite
                            icon="EcouteIcon"
                            titre="Ecoute"
                            description={[
                                "Nous prenons le temps d'écouter les besoins et envies de nos clients.",
                                "La communication est pour nous essentielle."
                            ]}
                        />
                    </Grid>
                    <Grid item>
                        <Qualite
                            icon="CreativiteIcon"
                            titre="Créativité"
                            description={[
                                "Si vous n'avez pas encore de logo, nous pouvons vous proposer la création d'une identité visuelle afin de compléter le site web.",
                                "Nous pouvons également réaliser des fichiers pour des cartes de visite, flyers, affiches etc. à transmettre à votre imprimeur."
                            ]}
                        />
                    </Grid>
                    <Grid item>
                        <Qualite
                            icon="BudgetIcon"
                            titre="Respect des budgets"
                            description={[
                                "Parce que nous sommes une entreprise à taille humaine, nous sommes conscients des difficultés que vous pouvez rencontrer.",
                                "Parce que nous pensons qu'avoir un site Internet est devenu indispensable et ne doit pas être un luxe, nos tarifs sont véritablement moins élevés que ceux proposés par les agences web."
                            ]}
                        />
                    </Grid>
                    <Grid item>
                        <Qualite
                            icon="SurMesureIcon"
                            titre="100 % sur-mesure"
                            description={[
                                "Pas de fonctionnalités superflues, un design et un contenu entièrement personnalisés en accord avec votre image et vos besoins."
                            ]}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}