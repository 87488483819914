import React, { Component } from 'react';
import BoutonMenu from './BoutonMenu';

import './MenuPrincipal.css'

class MenuPrincipal extends Component {
    render() {
        return (
            <div className='menuPrincipal'>
                <BoutonMenu libelle="ACCUEIL" couleurTriangle="violet" exact={true} chemin="/" />
                <BoutonMenu libelle="PRODUITS" couleurTriangle="rose" exact={false} chemin="/offre" />
                <BoutonMenu libelle="PORTFOLIO" couleurTriangle="peche" exact={false} chemin="/portfolio" />
                <BoutonMenu libelle="CONTACT" couleurTriangle="jaune" exact={false} chemin="/contact" />
            </div>
        );
    }
}

export default MenuPrincipal;