import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import TitrePage from "../components/TitrePage";
// Import des icons
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import DnsIcon from "@material-ui/icons/Dns";
import BusinessIcon from "@material-ui/icons/Business";
import InfoIcon from "@material-ui/icons/Info";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import MailIcon from "@material-ui/icons/Mail";
import ForumIcon from "@material-ui/icons/Forum";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const StyledCard = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    // boxShadow: "3px 4px 7px 0px rgba(50, 50, 50, 0.64)",
    width: 300,
    "& .MuiCardHeader-title": {
      fontFamily: "Montserrat",
      fontSize: "1.1rem",
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}))(Card);

export default function Mentions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='contenu'>
      <TitrePage titre='Mentions légales' couleur='orange' />
      <div className='fondDiamant paddingMoins'>
        <Grid container alignItems='stretch' justify='space-around'>
          <Grid item>
            <StyledCard>
              <CardHeader
                avatar={<BusinessIcon color='primary' />}
                title='IDENTIFICATION'
              />
              <CardContent>
                <Typography variant='body2' color='textPrimary' component='p'>
                  Softin'Media
                  <br />
                  5 rue de la Marlère
                  <br />
                  40300 CAGNOTTE
                  <br />
                </Typography>
                <Divider />
                <Typography variant='body2' color='textPrimary' component='p'>
                  Siren 509 907 309
                  <br />
                  APE 6209Z
                  <br />
                  N° TVA intracom. FR26 509 907 309
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton
                  color='secondary'
                  aria-label='En savoir plus'
                  href='https://www.infogreffe.fr/entreprise-societe/509907309-softin-medi--240109B000180000.html?typeProduitOnglet=EXTRAIT&afficherretour=false'
                  target='new'
                >
                  <InfoIcon />
                </IconButton>
              </CardActions>
            </StyledCard>
          </Grid>
          <Grid item>
            <StyledCard>
              <CardHeader
                avatar={<ForumIcon color='primary' />}
                title='CONTACT'
              />
              <CardContent>
                <Typography variant='body2' color='textPrimary' component='p'>
                  Par email via le formulaire de contact
                </Typography>
                <Divider />
                <Typography variant='body2' color='textPrimary' component='p'>
                  Par téléphone au 06 22 36 25 24
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton
                  component={Link}
                  color='secondary'
                  aria-label='Nous contacter par mail'
                  to='/contact'
                >
                  <MailIcon />
                </IconButton>
                <IconButton
                  color='secondary'
                  aria-label='Nous contacter par téléphone'
                  href='tel:+33622362524'
                >
                  <PhoneIphoneIcon />
                </IconButton>
              </CardActions>
            </StyledCard>
          </Grid>
          <Grid item>
            <StyledCard>
              <CardHeader
                avatar={<ArtTrackIcon color='primary' />}
                title='INFOS CONTENU'
              />
              <CardContent>
                <Typography variant='h3' color='secondary' paragraph>
                  Directrice de la publication
                </Typography>
                <Typography variant='body2' color='textPrimary' component='p'>
                  Ingrid Delteil
                </Typography>
                <Divider />
                <Typography variant='h3' color='secondary' paragraph>
                  Informations concernant les images
                </Typography>
                <Typography variant='body2' color='textPrimary' component='p'>
                  Les photos des réalisations sont la propriété de Softin'Media.
                  Toute utilisation ou reproduction est interdite.
                  <br />
                  Pour certaines de nos réalisations graphiques nous sommes
                  amenés à utiliser des images achetées sur le site Adobe Stock
                  que nous retravaillons.
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton
                  color='secondary'
                  aria-label='Nous contacter par mail'
                  href='https://www.linkedin.com/in/ingrid-delteil-153ab466'
                  target='new'
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  color='secondary'
                  aria-label='Adobe Stock'
                  href='https://stock.adobe.com/fr/'
                  target='new'
                >
                  <PhotoLibraryIcon />
                </IconButton>
              </CardActions>
            </StyledCard>
          </Grid>
          <Grid item>
            <StyledCard>
              <CardHeader
                avatar={<ImportantDevicesIcon color='primary' />}
                title='INFOS TECHNIQUES'
              />
              <CardContent>
                <Typography variant='h3' color='secondary' paragraph>
                  Réalisation du site
                </Typography>
                <Typography variant='body2' color='textPrimary' component='p'>
                  Softin'Media
                </Typography>
                <Divider />
                <Typography variant='h3' color='secondary' paragraph>
                  Hébergeur
                </Typography>
                <Typography variant='body2' color='textPrimary' component='p'>
                  Firebase
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton
                  color='secondary'
                  aria-label='Firebase'
                  href='https://firebase.google.com/'
                  target='new'
                >
                  <DnsIcon />
                </IconButton>
              </CardActions>
            </StyledCard>
          </Grid>
        </Grid>
        <div className='espaceBas'></div>
      </div>
    </div>
  );
}
