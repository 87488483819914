import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './TitrePage.css'

class TitrePage extends Component {
    render() {
        const titre = this.props.titre
        const couleur = this.props.couleur
        const classTriangle = 'triangle ' + couleur
        return (
            <div className='conteneur'>
                <div className={classTriangle}/>
                <h1>{titre}</h1>
            </div>
        );
    }
}

TitrePage.propTypes = {
    titre: PropTypes.string.isRequired,
    couleur: PropTypes.oneOf(['violet', 'rose', 'peche', 'jaune', 'orange']).isRequired
};

export default TitrePage;