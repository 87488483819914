import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom"
import BarreNavigation from './components/Navigation/BarreNavigation'
// import Navigation from './components/Navigation/Navigation'
import Footer from './components/Footer'
import Home from './views/home'
import Offre from './views/offre'
import Portfolio from './views/portfolio'
import Contact from './views/contact'
import Mentions from './views/mentions'
import './App.css'


export default function App(props) {
    // render() {
        return (
            <Router>
                <BarreNavigation/>
                <Route path="/" exact component={Home} />
                <Route path="/offre" exact component={Offre} />
                <Route path="/portfolio" exact component={Portfolio} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/mentions" exact component={Mentions} />
                <Footer/>
            </Router>
        );
    // }
}

// export default App
