import React from 'react';
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import StyleIcon from '@material-ui/icons/Style'
import BurstModeIcon from '@material-ui/icons/BurstMode'
import MailIcon from '@material-ui/icons/Mail'

const useStyles = makeStyles((theme) => ({
    hamburger: {
        marginLeft: '10px'
    },
    list: {
        '& .listItemText': {
            color: '#414042',
        },
        '& .listItemIcon': {
            color: '#923088',
        },
        '&:hover, &:focus': {
            backgroundColor: '#B02E85',
            '& .listItemIcon, & .listItemText': {
                color: '#ffffff',
            },
        }
    },
    listActive: {
        backgroundColor: '#EB5F7A',
        '& .listItemIcon, & .listItemText': {
            color: '#ffffff',
        }
    },
}));

export default function Hamburger() {
    const classes = useStyles();
    const [etatMenuMobile, setEtatMenuMobile] = React.useState(false);

    const toggleMenuMobile = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setEtatMenuMobile(open);
    };

    const MenuMobile = () => (
        <div
            onClick={toggleMenuMobile(false)}
            onKeyDown={toggleMenuMobile(false)}
        >
            <List component="nav" aria-label="Menu Mobile">
                <ListItem component={NavLink} exact to='/' autoFocus={false} className={classes.list} activeClassName={classes.listActive}>
                    <ListItemIcon className="listItemIcon">
                        <HomeIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="ACCUEIL" className="listItemText" />
                </ListItem>
                <ListItem component={NavLink} to='/offre' className={classes.list} activeClassName={classes.listActive}>
                    <ListItemIcon className="listItemIcon">
                        <StyleIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="PRODUITS" className="listItemText" />
                </ListItem>
                <ListItem component={NavLink} to='/portfolio' className={classes.list} activeClassName={classes.listActive}>
                    <ListItemIcon className="listItemIcon">
                        <BurstModeIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="PORTFOLIO" className="listItemText" />
                </ListItem>
                <ListItem component={NavLink} to='/contact' className={classes.list} activeClassName={classes.listActive}>
                    <ListItemIcon className="listItemIcon">
                        <MailIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="CONTACT" className="listItemText" />
                </ListItem>
            </List>
        </div>
    );

    return (
        <div>
            <IconButton edge="start" color="primary" aria-label="menu" onClick={toggleMenuMobile(true)} className={classes.hamburger}>
                <MenuIcon />
            </IconButton>
            <Drawer anchor='right' open={etatMenuMobile} onClose={toggleMenuMobile(false)} className={classes.menuMobile}>
                {MenuMobile()}
            </Drawer>
        </div>
    );
}