import React, { Component } from 'react';
import PropTypes from 'prop-types';

const containerFixeStyle = {
    height: '40px',
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    textAlign: "center"
}

const containerAnimeStyle = {
    position: "relative",
    top: "0px",
    display: "inline-block"
}
const h2Style = {
    fontSize: "2rem",
    fontWeight: 800,
    margin: 0,
    color: "#EB5F7A"
}

class TextAnimation extends Component {
    constructor(props) {
        super(props)
        this.texteAnime = React.createRef()
        this.animation = null
        this.actualTop = 0
        this.inc = 0
        this.repeat = null
        this.steps = 0
    }

    componentDidMount() {
        this.animerTexte()
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.animation)
        window.clearTimeout(this.repeat)
    }

    animerTexte = () => {
        if (this.steps === this.props.phrases.length) { // 1
            this.texteAnime.current.style.top = 0
            this.actualTop = 0
            this.steps = 0
        }
        if (this.inc === 39) { 
            this.inc = 0 
            this.steps += 1
            window.cancelAnimationFrame(this.animation) 
            this.repeat = window.setTimeout(this.animerTexte, this.props.vitesse)
            return
        }
        const newTop = this.actualTop - 1
        this.texteAnime.current.style.top = newTop.toString() + 'px'
        this.inc += 1
        this.actualTop = newTop
        this.animation = window.requestAnimationFrame(this.animerTexte)
    }
    render() {
        const newTexts = this.props.phrases.slice(0)
        newTexts.push(this.props.phrases[0])
        return (
            <div style={containerFixeStyle}>
                <div ref={this.texteAnime} style={containerAnimeStyle}>
                    {newTexts.map((item, index) => (
                        <h2 style={h2Style} key={'scrolling-text' + index}>{item}</h2>
                    ))}
                </div>
            </div>
        );
    }
}

TextAnimation.propTypes = {
    phrases: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    vitesse: PropTypes.number.isRequired,
};

export default TextAnimation;