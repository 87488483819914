import React, { useEffect } from 'react';
import TitrePage from '../components/TitrePage'
import { Grid } from '@material-ui/core'
import CardProduit from '../components/CardProduit';

import './offre.css'

const listCaracteristiquesVitrine = [
    'Une ou plusieurs page(s) de présentation',
    'Formulaire de contact',
    'Site responsive (qui s\'adapte à la taille de l\'écran)'
];

const listUtilisationVitrine = [
    'Présentation de l\'activité',
    'Site qui évolue peu'
];

const listTarifVitrine = [
    'Hébergement',
    '2 noms de domaine',
    'Maintenance couvrant les mises à jour mineures',
];

const listCaracteristiquesPortfolio = [
    'Une ou plusieurs page(s) de présentation',
    'Une ou plusieurs page(s) catalogue / portfolio présentant les produits, réalisations etc.',
    'Formulaire de contact',
    'Site responsive (qui s\'adapte à la taille de l\'écran)'
];

const listUtilisationPortfolio = [
    'Présentation de l\'activité',
    'Catalogue de produits ou Portfolio de réalisations',
    'Site qui évolue régulièrement'
];

const listTarifPortfolio = [
    'Hébergement',
    '2 noms de domaine',
    'Maintenance couvrant les mises à jour mineures',
];

const listCaracteristiquesBoutique = [
    'Une ou plusieurs page(s) de présentation',
    'Une ou plusieurs page(s) catalogue / portfolio présentant les produits, réalisations etc.',
    'Gestion de panier et de paiement en ligne',
    'Formulaire de contact',
    'Site responsive (qui s\'adapte à la taille de l\'écran)'
];

const listUtilisationBoutique = [
    'Présentation de l\'activité',
    'Catalogue de produits ou Portfolio de réalisations',
    'Boutique en ligne',
    'Site qui évolue régulièrement'
];

const listTarifBoutique = [
    'Hébergement',
    '2 noms de domaine',
    'Maintenance couvrant les mises à jour mineures',
];

export default function Offre() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='contenu'>
            <TitrePage titre="Sites Internet 100% sur-mesure" couleur="rose" />
            <div className='fondDiamant'>
                <Grid container justify="center" alignItems="center">
                    <Grid item> 
                        <img src="mockup.png" alt="Développement de sites Internet" className="imgMockup" />
                    </Grid>
                </Grid>
                <Grid container justify="space-around" alignItems="flex-start">
                    <Grid item xs={12} lg={3}>
                        <CardProduit produit="SITE VITRINE" prix={500} caracteristiques={listCaracteristiquesVitrine} utilisation={listUtilisationVitrine} inclus={listTarifVitrine}/>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <CardProduit produit="SITE PORTFOLIO" prix={1000} caracteristiques={listCaracteristiquesPortfolio} utilisation={listUtilisationPortfolio} inclus={listTarifPortfolio}/>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <CardProduit produit="SITE BOUTIQUE" prix={1500} caracteristiques={listCaracteristiquesBoutique} utilisation={listUtilisationBoutique} inclus={listTarifBoutique}/>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}