import React from 'react';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'
import MailIcon from '@material-ui/icons/Mail'
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    chip: {
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        width: "100%",
        fontSize: "1.1rem",
        height: "50px"
    },
}));

export default function DialogDemandeDevis(props) {
    const classes = useStyles();
    const produit = props.produit;
    const [open, setOpen] = React.useState(false);
    const [prenom, setPrenom] = React.useState('');
    const [prenomErreur, setPrenomErreur] = React.useState(false);
    const [prenomErreurMessage, setPrenomErreurMessage] = React.useState('');
    const [nom, setNom] = React.useState('');
    const [nomErreur, setNomErreur] = React.useState(false);
    const [nomErreurMessage, setNomErreurMessage] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [emailErreur, setEmailErreur] = React.useState(false);
    const [emailErreurMessage, setEmailErreurMessage] = React.useState('');
    const [tel, setTel] = React.useState('');
    const [telErreur, setTelErreur] = React.useState(false);
    const [telErreurMessage, setTelErreurMessage] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        resetFormulaire()
    };

    const handleChangePrenom = (e) => {
        setPrenomErreur(false)
        setPrenomErreurMessage('')
        setPrenom(e.target.value.toUpperCase());
    }

    const handleChangeNom = (e) => {
        setNomErreur(false)
        setNomErreurMessage('')
        setNom(e.target.value.toUpperCase());
    }

    const handleChangeEmail = (e) => {
        setEmailErreur(false)
        setEmailErreurMessage('')
        setEmail(e.target.value);
    }

    const handleChangeTel = (e) => {
        setTelErreur(false)
        setTelErreurMessage('')
        const regex = RegExp('^0')
        e.target.value = e.target.value.replace(/\D+/, '')
        if (!regex.test(e.target.value)) {
            e.target.value = e.target.value.replace(/^/, '0')
        }
        // Stick to first 10, ignore later digits
        e.target.value = e.target.value.slice(0, 10)
        setTel(e.target.value);
    }

    const [openSnackOk, setOpenSnackOk] = React.useState(false);

    const handleClickSnackOk = () => {
        setOpenSnackOk(true);
    };

    const handleCloseSnackOk = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackOk(false);
        handleClose();
    };

    const [openSnackError, setOpenSnackError] = React.useState(false);

    const handleClickSnackError = () => {
        setOpenSnackError(true);
    };

    const handleCloseSnackError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackError(false);
    };

    const resetFormulaire = () => {
        setPrenom('')
        setPrenomErreur(false)
        setPrenomErreurMessage('')
        setNom('')
        setNomErreur(false)
        setNomErreurMessage('')
        setTel('')
        setTelErreur(false)
        setTelErreurMessage('')
        setEmail('')
        setEmailErreur(false)
        setEmailErreurMessage('')
    }
    const validationFormulaire = () => {
        // Vérification du prénom (longueur supérieure à 1 sans les espaces)
        if (prenom.replace(' ', '').length < 1) {
            setPrenomErreur(true)
            setPrenomErreurMessage('Merci de préciser votre prénom')
            return false
        } else {
            setPrenomErreur(false)
            setPrenomErreurMessage('')
        }
        // Vérification du nom (longueur supérieure à 1 sans les espaces)
        if (nom.replace(' ', '').length < 1) {
            setNomErreur(true)
            setNomErreurMessage('Merci de préciser votre Nom')
            return false
        } else {
            setNomErreur(false)
            setNomErreurMessage('')
        }
        // Vérification du numéro de téléphone (longueur = 10 sans les espaces)
        if (tel.replace(' ', '').length !== 10) {
            setTelErreur(true)
            setTelErreurMessage('Merci de préciser un numéro de téléphone valide')
            return false
        } else {
            setTelErreur(false)
            setTelErreurMessage('')
        }
        // Vérification de l'adresse email
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (regex.test(email)) {
            setEmailErreur(false)
            setEmailErreurMessage('')
        } else {
            setEmailErreur(true)
            setEmailErreurMessage('Merci de préciser une adresse email valide : '+email)
            return false
        }

        return true
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const testValidationFormulaire = validationFormulaire()
        if (testValidationFormulaire === true) {
            axios
              .post(
                "https://us-central1-softin-media.cloudfunctions.net/mailContact",
                {
                    "objet": "Demande de devis",
                    "prenom": prenom,
                    "nom": nom,
                    "tel": tel,
                    "email": email,
                    "message": produit,
                },
                { headers: { "Content-Type": "application/json" } }
              )
              .then((response) => {
                if (response.data.status === "success") {
                  handleClickSnackOk();
                } else if (response.data.status === "fail") {
                  handleClickSnackError();
                }
              });
        }
    };

    return (
        <div>
            <Chip
                className={classes.chip}
                icon={<MailIcon />}
                label="Demander un devis gratuit"
                clickable
                color="secondary"
                onClick={handleClickOpen}
            />
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" disableTypography>
                    <Typography variant="h1" align="center" color="secondary" component="p">
                        DEMANDE DE DEVIS
                        </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Remplissez le formulaire ci-dessous et nous vous contacterons pour parler ensemble de votre projet.
                            </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="prenom"
                        id="prenom"
                        label="Prénom"
                        type="text"
                        fullWidth
                        required
                        onChange={handleChangePrenom}
                        value={prenom}
                        error={prenomErreur}
                        helperText={prenomErreurMessage}
                    />
                    <TextField
                        margin="dense"
                        name="nom"
                        id="nom"
                        label="Nom"
                        type="text"
                        fullWidth
                        required
                        onChange={handleChangeNom}
                        value={nom}
                        error={nomErreur}
                        helperText={nomErreurMessage}
                    />
                    <TextField
                        margin="dense"
                        name="tel"
                        id="tel"
                        label="Téléphone"
                        type="text"
                        fullWidth
                        required
                        onChange={handleChangeTel}
                        value={tel}
                        error={telErreur}
                        helperText={telErreurMessage}
                    />
                    <TextField
                        margin="dense"
                        name="email"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        required
                        onChange={handleChangeEmail}
                        error={emailErreur}
                        helperText={emailErreurMessage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="contained">
                        Annuler
                            </Button>
                    <Button onClick={handleSubmit} color="secondary" variant="contained">
                        Envoyer
                            </Button>
                </DialogActions>
                <Snackbar open={openSnackOk} autoHideDuration={3000} onClose={handleCloseSnackOk}>
                    <Alert onClose={handleCloseSnackOk} severity="success">
                        Votre demande a bien été envoyée. Nous vous répondrons dans les meilleurs délais
                            </Alert>
                </Snackbar>
                <Snackbar open={openSnackError} autoHideDuration={3000} onClose={handleCloseSnackError}>
                    <Alert onClose={handleCloseSnackError} severity="error">
                        Votre demande n'a pa pu être envoyée. Merci de réessayer ultérieurement.
                    </Alert>
                </Snackbar>
            </Dialog>
        </div>
    );
}