import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#923088",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: '100%', 
        padding: theme.spacing(1)
    },
    link: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: theme.palette.common.white,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    text: {
        color: theme.palette.common.white,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}));

export default function Footer() {
    const classes = useStyles();

    return (
        <Grid container alignItems="center" justify="space-around" className={classes.root}>
            <Grid item>
                <Typography><Link to="/mentions" className={classes.link}>Mentions Légales</Link></Typography>
            </Grid>
            <Grid item>
                <Typography><a href="tel:+33622362524" className={classes.link}>06 22 36 25 24</a></Typography>
            </Grid>
            <Grid item>
                <Typography className={classes.text}>Réalisation Softin'Medi@</Typography>
            </Grid>
        </Grid>
    );
}