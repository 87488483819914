import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';

import './CardContact.css'

class CardContact extends Component {

    render() {
        return (
            <div>
                <div className="cardFond">
                    <div className="cardContenu">
                        <p>Nos équipes vous répondent au</p>
                        <Link to='tel:+33622362524' className='lienTel'>06 22 36 25 24</Link>
                        <p>Du lundi au vendredi de 9h à 17h</p>
                    </div>
                </div>
                <div className="cardImage">
                    <PhoneInTalkIcon fontSize="large" className='cardIcon' />
                </div>
            </div>
        );
    }
}

CardContact.propTypes = {

};

export default CardContact;