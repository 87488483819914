import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import './BoutonMenu.css'

class BoutonMenu extends Component {
    render() {
        const libelle = this.props.libelle
        const couleurTriangle = this.props.couleurTriangle
        const exact = this.props.exact
        const chemin = this.props.chemin
        const classLibelle = 'libelle lib-' + couleurTriangle
        return (
            <NavLink className='bouton' activeClassName='boutonActif' exact={exact} to={chemin} >
                <div id="triangle" className={couleurTriangle} />
                <div className={classLibelle}>{libelle}</div>
            </NavLink>
        );
    }
}

BoutonMenu.propTypes = {
    libelle: PropTypes.string.isRequired,
    couleurTriangle: PropTypes.oneOf(['violet', 'rose', 'peche', 'jaune']).isRequired,
    exact: PropTypes.bool.isRequired,
    chemin: PropTypes.string.isRequired
};

export default BoutonMenu;