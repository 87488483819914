import React from 'react';
import PropTypes from 'prop-types';
import EcouteIcon from '@material-ui/icons/Hearing'
import CreativiteIcon from '@material-ui/icons/WbIncandescent'
import BudgetIcon from '@material-ui/icons/Euro';
import SurMesureIcon from '@material-ui/icons/Style';
import InterrogationIcon from '@material-ui/icons/Help';

import './Qualite.css'

Qualite.propTypes = {
    icon: PropTypes.oneOf(['EcouteIcon', 'CreativiteIcon', 'BudgetIcon', 'SurMesureIcon']).isRequired,
    titre: PropTypes.string.isRequired,
    description: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

function Qualite(props) {
    return (
        <div>
            <div className="qualiteImage">
                {(props.icon === 'EcouteIcon') ?
                    <EcouteIcon fontSize="large" className='qualiteIcon' />
                    : (props.icon === 'CreativiteIcon') ?
                        <CreativiteIcon fontSize="large" className='qualiteIcon' />
                        : (props.icon === 'BudgetIcon') ?
                            <BudgetIcon fontSize="large" className='qualiteIcon' />
                            : (props.icon === 'SurMesureIcon') ?
                                <SurMesureIcon fontSize="large" className='qualiteIcon' />
                                : <InterrogationIcon fontSize="large" className='qualiteIcon' />
                }
            </div>
            <div className="qualiteFond">
                <div className="qualiteTitre">
                    <p>{props.titre}</p>
                </div>
                <div className="qualiteContenu">
                    {props.description.map((phrase, index) => (
                        <p key={index}>{phrase}</p>
                    ))} 
                </div>
            </div>
        </div>
    );
}

export default Qualite;