import React, { useEffect } from 'react';
import TitrePage from '../components/TitrePage'
import Realisation from '../components/Realisation'

const Realisations = [
    {
        image : "./prioleaud2020.jpg",
        nom : "PRIOLEAUD 2020",
        type : "Site Vitrine",
        techno : "Développement PHP",
        responsive : "Site responsive",
        description: [
            "Ce site a été créé pour la campagne électorale des municipales de 2020"
        ],
        adresse : "https://www.prioleaud2020.fr"
    },
    {
        image: "./bosselut.jpg",
        nom: "BOSSELUT AUTOMOBILES",
        type: "Site Portfolio",
        techno: "Développement PHP",
        responsive: "Site responsive",
        description: [
            "Ce site présente l'activité d'un garage automobile.",
            "Le site va très prochainement évoluer avec des fonctionnalités de boutique en ligne.",
            "Le passage à React.js et Node.js est à l'étude."
        ],
        adresse: "https://www.bosselut-automobiles.com"
    },
    {
        image: "./natureo.jpg",
        nom: "NATURÉO",
        type: "Site Portfolio",
        techno: "Développement PHP",
        responsive: "Site responsive",
        description: [
            "Site d'un client spécialisé dans les revêtements de sols et aménagements extérieurs.",
            "Le client peut alimenter la base de données de ses réalisations via un backoffice."
        ],
        adresse: "https://www.onatureo.com"
    },
    {
        image: "./equusymbiose.jpg",
        nom: "EQUUSYMBIOSE",
        type: "Site Vitrine",
        techno: "Développement PHP",
        responsive: "Site responsive",
        description: [
            "Le site d'une cliente qui travaille dans le milieu équin et qui propose des solutions complètes pour le soin des chevaux."
        ],
        adresse: "https://www.equusymbiose.com"
    },
    {
        image: "./happyquus.jpg",
        nom: "HAPPYQUUS",
        type: "Site Boutique",
        techno: "Développement PHP",
        responsive: "Site non responsive",
        description: [
            "La boutique en ligne de la même cliente qu'EQUUSYMBIOSE.",
            "Site essentiellement dédié à la nutrition des chevaux.",
            "Une refonte complète du site est prévue prochainement afin de le rendre plus moderne et responsive."
        ],
        adresse: "https://www.happyquus.com"
    }
]
export default function Portfolio() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='contenu'>
            <TitrePage titre="Comme eux, faîtes-nous confiance" couleur="peche" />
            <div className='fondDiamant'>
                {Realisations.map((realisation, index) => (
                    <Realisation
                        key={index}
                        image={realisation.image}
                        nom={realisation.nom}
                        type={realisation.type}
                        techno={realisation.techno}
                        responsive={realisation.responsive}
                        description={realisation.description}
                        adresse={realisation.adresse}
                    />
                ))}
            </div>
        </div>
    )
}