import React from "react";
import {Grid} from "@material-ui/core";
import DialogDemandeDevis from "./DialogDemandeDevis";
import BuildIcon from "@material-ui/icons/Build";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";

import "./CardProduit.css";

export default function CardProduit(props) {
  return (
    <div className='cardProduitFond'>
      <div className='cardProduitTitre'>{props.produit}</div>
      <div className='cardProduitContenu'>
        <Grid container alignItems='center'>
          <Grid item>
            <BuildIcon color='primary' />
          </Grid>
          <Grid item>
            <p className='cardProduitSousTitre'>CARACTÉRISTIQUES</p>
          </Grid>
        </Grid>
        <ul className='cardProduitListe'>
          {props.caracteristiques.map((itemText, index) => (
            <li key={index} className='puceTrianglePeche'>
              {itemText}
            </li>
          ))}
        </ul>
        <Grid container alignItems='center'>
          <Grid item>
            <ImportantDevicesIcon color='primary' />
          </Grid>
          <Grid item>
            <p className='cardProduitSousTitre'>UTILISATION</p>
          </Grid>
        </Grid>
        <ul className='cardProduitListe'>
          {props.utilisation.map((itemText, index) => (
            <li key={index} className='puceTrianglePeche'>
              {itemText}
            </li>
          ))}
        </ul>
        <Grid container alignItems='center'>
          <Grid item>
            <AllInclusiveIcon color='primary' />
          </Grid>
          <Grid item>
            <p className='cardProduitSousTitre'>INCLUS POUR 1 AN</p>
          </Grid>
        </Grid>
        <ul className='cardProduitListe'>
          {props.inclus.map((itemText, index) => (
            <li key={index} className='puceTrianglePeche'>
              {itemText}
            </li>
          ))}
        </ul>
      </div>
      {/* <div className="cardProduitPrix">
                A partir de {props.prix} € HT
            </div> */}
      <div>
        <DialogDemandeDevis produit={props.produit} />
      </div>
    </div>
  );
}
