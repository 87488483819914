import React from 'react';
import { Grid, Button } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'

import './Realisation.css'

function Realisation(props) {
    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={5} className="reaBoiteImage">
                    <a href={props.adresse} target="new"><img className="reaImage" alt={props.nom} src={props.image}/></a>             
                </Grid>
                <Grid item xs={12} md={5} className="reaBoiteContenu">
                    <p className="reaTitre">
                        {props.nom}
                    </p>
                    <p className="reaCaracteristiques">
                        {props.type}<br />
                        {props.techno}<br />
                        {props.responsive}
                    </p>
                    <div className="reaDescription">
                        {props.description.map((phrase, index) => (
                            <p 
                                key={index}
                            >
                                {phrase}
                            </p>
                        ))}
                    </div>
                    <div className="reaLien">
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<VisibilityIcon />}
                            href={props.adresse}
                            target="new"
                        >
                                Voir le site
                        </Button>
                    </div>
                </Grid>
                {/* <Grid item>
                    Témoignage du client
                </Grid> */}
            </Grid>
        </div>
    );
}

export default Realisation;