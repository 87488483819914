import React, { useEffect } from 'react';
import axios from 'axios';
import TitrePage from '../components/TitrePage';
import CardContact from '../components/CardContact'
import { Grid, TextField, Button, Snackbar } from '@material-ui/core';
import TextAnimation from '../components/TextAnimation'
import { useBreakpoint } from '../components/Breakpoint'
import MailIcon from '@material-ui/icons/Mail';
import MuiAlert from '@material-ui/lab/Alert';

import './contact.css'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Contact() {
    const phrases = [
        "Vous n'avez pas encore de site Internet",
        "Vous voulez changer de site Internet",
        "Nous avons la solution qui correspond à vos besoins",
        "Appelez-nous ou écrivez-nous",
        "Nous parlerons de votre projet",
        "Les devis sont gratuits alors n'hésitez plus"
    ]
    const breakpoints = useBreakpoint()
    const [prenom, setPrenom] = React.useState('');
    const [prenomErreur, setPrenomErreur] = React.useState(false);
    const [nom, setNom] = React.useState('');
    const [nomErreur, setNomErreur] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailErreur, setEmailErreur] = React.useState(false);
    const [tel, setTel] = React.useState('');
    const [telErreur, setTelErreur] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [messageErreur, setMessageErreur] = React.useState(false);

    const handleChangePrenom = (e) => {
        setPrenomErreur(false)
        setPrenom(e.target.value.toUpperCase());
    }

    const handleChangeNom = (e) => {
        setNomErreur(false)
        setNom(e.target.value.toUpperCase());
    }

    const handleChangeEmail = (e) => {
        setEmailErreur(false)
        setEmail(e.target.value);
    }

    const handleChangeMessage = (e) => {
        setMessageErreur(false)
        setMessage(e.target.value);
    }

    const handleChangeTel = (e) => {
        setTelErreur(false)
        const regex = RegExp('^0')
        e.target.value = e.target.value.replace(/\D+/, '')
        if (!regex.test(e.target.value)) {
            e.target.value = e.target.value.replace(/^/, '0')
        }
        // Stick to first 10, ignore later digits
        e.target.value = e.target.value.slice(0, 10)
        setTel(e.target.value);
    }

    const [openSnackOk, setOpenSnackOk] = React.useState(false);

    const handleClickSnackOk = () => {
        setOpenSnackOk(true);
    };

    const handleCloseSnackOk = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        resetFormulaire()
        setOpenSnackOk(false);
        
    };

    const [openSnackError, setOpenSnackError] = React.useState(false);

    const handleClickSnackError = () => {
        setOpenSnackError(true);
    };

    const handleCloseSnackError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackError(false);
    };

    const resetFormulaire = () => {
        setPrenom('')
        setPrenomErreur(false)
        setNom('')
        setNomErreur(false)
        setTel('')
        setTelErreur(false)
        setEmail('')
        setEmailErreur(false)
        setMessage('')
        setMessageErreur(false)
    }

    const validationFormulaire = () => {
        // Vérification du prénom (longueur supérieure à 1 sans les espaces)
        if (prenom.replace(' ', '').length < 1) {
            setPrenomErreur(true)
            return false
        } else {
            setPrenomErreur(false)
        }
        // Vérification du nom (longueur supérieure à 1 sans les espaces)
        if (nom.replace(' ', '').length < 1) {
            setNomErreur(true)
            return false
        } else {
            setNomErreur(false)
        }
        // Vérification du numéro de téléphone (longueur = 10 sans les espaces)
        if (tel.replace(' ', '').length !== 10) {
            setTelErreur(true)
            return false
        } else {
            setTelErreur(false)
        }
        // Vérification de l'adresse email
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (regex.test(email)) {
            setEmailErreur(false)
        } else {
            setEmailErreur(true)
            return false
        }
        // Vérification du message (longueur supérieure à 1 sans les espaces)
        if (message.replace(' ', '').length < 1) {
            setMessageErreur(true)
            return false
        } else {
            setMessageErreur(false)
        }

        return true
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const testValidationFormulaire = validationFormulaire()
        if (testValidationFormulaire === true) {
            axios
              .post(
                "https://us-central1-softin-media.cloudfunctions.net/mailContact",
                  {
                    "objet": "Message depuis le site",
                    "prenom": prenom,
                    "nom": nom,
                    "tel": tel,
                    "email": email,
                    "message": message },
                { headers: { "Content-Type": "application/json" } }
              )
              .then((response) => {
                if (response.data.status === "success") {
                  handleClickSnackOk();
                } else if (response.data.status === "fail") {
                  handleClickSnackError();
                }
              });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='contenu'>
            <TitrePage titre="Une question ? Besoin d'un devis ?" couleur="jaune" />
            <div className='fondDiamant paddingPlus'>
                <Grid container justify="space-around">
                    <Grid item>
                        <CardContact/>
                    </Grid>
                </Grid>
                {(breakpoints['lg'] || breakpoints['xl']) ?
                    <Grid container justify="space-around"><Grid item><TextAnimation phrases={phrases} vitesse={3000}/></Grid></Grid> : ''
                }
                <Grid container justify="space-around" >
                    <Grid item>
                        <form className="formulaire" noValidate autoComplete="off">
                            <div>
                                <TextField
                                    className="saisie"
                                    autoFocus
                                    margin="dense"
                                    name="prenom"
                                    id="prenom"
                                    label="Prénom"
                                    type="text"
                                    fullWidth
                                    required
                                    onChange={handleChangePrenom}
                                    value={prenom}
                                    error={prenomErreur}
                                />
                                <TextField
                                    margin="dense"
                                    name="nom"
                                    id="nom"
                                    label="Nom"
                                    type="text"
                                    fullWidth
                                    required
                                    onChange={handleChangeNom}
                                    value={nom}
                                    error={nomErreur}
                                />
                                <TextField
                                    margin="dense"
                                    name="tel"
                                    id="tel"
                                    label="Téléphone"
                                    type="text"
                                    fullWidth
                                    required
                                    onChange={handleChangeTel}
                                    value={tel}
                                    error={telErreur}
                                />
                                <TextField
                                    margin="dense"
                                    name="email"
                                    id="email"
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    required
                                    onChange={handleChangeEmail}
                                    value={email}
                                    error={emailErreur}
                                />
                                <TextField
                                    margin="dense"
                                    name="message"
                                    id="message"
                                    label="Message"
                                    type="text"
                                    fullWidth
                                    required
                                    onChange={handleChangeMessage}
                                    value={message}
                                    error={messageErreur}
                                />
                            </div>
                            <div className="boutonSubmit">
                                <Button onClick={handleSubmit} color="secondary" variant="contained">
                                    Envoyer
                                </Button>    
                            </div>
                        </form>
                        <div className="logoMail">
                            <MailIcon fontSize="large" className='cardIcon' />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Snackbar open={openSnackOk} autoHideDuration={3000} onClose={handleCloseSnackOk}>
                <Alert onClose={handleCloseSnackOk} severity="success">
                    Votre demande a bien été envoyée. Nous vous répondrons dans les meilleurs délais
                            </Alert>
            </Snackbar>
            <Snackbar open={openSnackError} autoHideDuration={3000} onClose={handleCloseSnackError}>
                <Alert onClose={handleCloseSnackError} severity="error">
                    Votre demande n'a pa pu être envoyée. Merci de réessayer ultérieurement.
                    </Alert>
            </Snackbar>
        </div>
    )
}