import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Application from './App';
import softinTheme from './theme.json';
import * as serviceWorker from './serviceWorker';
import { BreakpointProvider } from './components/Breakpoint'

const queries = {
    xs: '(max-width: 575.98px)',
    sm: '(min-width: 576px) and (max-width: 767.98px)',
    md: '(min-width: 768px) and (max-width: 991.98px)',
    lg: '(min-width: 992px) and (max-width: 1199.98px)',
    xl: '(min-width: 1200px)'
}
const theme = createMuiTheme(softinTheme);

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <BreakpointProvider queries={queries}>
            <Application />
        </BreakpointProvider>
    </MuiThemeProvider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
