import React from 'react';
import { Link } from 'react-router-dom'
import { useBreakpoint } from '../Breakpoint'
import MenuPrincipal from './MenuPrincipal';
import Hamburger from './Hamburger';

import './BarreNavigation.css'

export default function BarreNavigation() {
    const breakpoints = useBreakpoint()

    const [headerBordure, setHeaderBordure] = React.useState("barreNavigation sansBordure")
        const listenScrollEvent = () => {
            window.scrollY > 10
                ? setHeaderBordure("barreNavigation avecBordure")
                : setHeaderBordure("barreNavigation sansBordure")
        }
        // Similar to componentDidMount and componentDidUpdate:
        React.useEffect(() => {
            window.addEventListener("scroll", listenScrollEvent)
        })
        return (
            <div className={headerBordure}>
                <Link to='/'><img src='/logo.svg' alt="Softin'Media" className='logo'/></Link>
                {(breakpoints['lg'] || breakpoints['xl']) ? <MenuPrincipal /> : <Hamburger />}
            </div>
        );
}